.topLabel{
    color: red;
    margin-top: 20px;
}
.secondSectionBox{
    background-color: #3366CC;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    height: 170px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2);
    font-weight: 500;
    margin-bottom: 20px;
}
.secondSectionBoxTitle{
    color: aliceblue;
    font-weight: bold;
    font-size: 30px;
}
.secondSectionBoxSubTitle{
    color: azure;
    font-weight: 400;
    font-size: 18px;
}
.separatorQuestion{
    margin-top: -20px;
}