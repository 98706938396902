.next{
    background-color: aqua;
}
.select-wrapper+label{
    color: black;
    font-size: 12px;
}
.separator{
    margin-top: 50px;
}
.input-field.col label{
    color: black;
    font-size: 15px;
    font-weight: 600;
    padding-bottom: 50px;
    /* background-color: aqua; */
    height: 50px;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
-webkit-appearance: none; 
margin: 0; 
}
input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}