.instructionsTitle{
    color:black;
    font-weight: bold;
}
.instructionsubTitle{
    color:#3366CC;
    font-weight: bold;
}
.instructionParagraph{
    font-weight: 400;
    font-size: 20px;
}
.instructionParagraphLite{
    font-weight: 600;
    font-size: 16px;
}
/* Instructions Pilot */
.instructionsubTitlePilot{
    color:#3366CC;
    font-weight: bold;
    font-size: 20px;
}
.instructionParagraphPilot{
    font-weight: 400;
    font-size: 20px;
}
.btnContainerPilot{
    display: grid;
    justify-content: center;
    align-content: center;
    width: 100%;
}
.bg-btnPilot{
    width: 600px;
    background-color: #3366CC;
    color: aliceblue;
    font-weight: bold;
    font-size: 20px;
}
/* Questions Pilot */
.questionsTitlePilot{
    color:rgb(255, 94, 0);
    font-weight: bold;
}
.questionsSubTitlePilot{
    color:rgb(34, 67, 133);
    font-weight: bold;
    font-size: 20px;
}