.fGeneralDetailsTitle{
    color:black;
    font-weight: 600;
    font-size: 30px;
}
.fGeneralDetailsSubTitle{
    color:#3366CC;
    font-weight: 400;
    font-size: 20px;
}
.SubTitle{
    color:black;
    font-weight: 400;
    font-size: 18px;
}
.bg-btn{
    background-color: #3366CC;
    color: aliceblue;
    width: 100%;
}
.bg-btn:hover{
    background-color: #004477;
}