.navigator{
    align-content: center;
    justify-content: space-between;
    display: grid;
    grid-template-areas: ' logoMen  logoUpn ';
    background-color: #004477;
    width: 100vw;
    z-index: 5;
}
nav{
    background-color: #004477;
}
.navbar{
    height: 150px;
}
.logoMen{
    grid-area: logoMen;
    width:450px;
    padding-top: 20px;
}
.logoUpn{
    grid-area: logoUpn;
    height: 150px;
    /* background-color: peru; */
}
.navTitle{
    grid-area: navTitle;
    font-size: 30px;
    width: 450px;
    /* background-color: pink; */
}