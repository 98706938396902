.lema{
    background-color: #E2ECFD;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2);
    font-weight: 500;
}
.sectionBox{
    background-color: rgba(216, 216, 216, 0.241);
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2);
    font-weight: 500;
}