.enabledSelect{
    display: block;
}
.disabledSelect{
    display: none;
}
.firstSectionBox{
    background-color: #3366CC;
    color: aliceblue;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    height: 60px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2);
    font-weight: 500;
    margin-bottom: 20px;
}
.radioSeparator{
    margin-right: 20px;
}
.radioAligner{
    Padding-top: 10px;
    align-content: center;
    justify-content: end;
    display: grid;
    grid-template-areas: ' Yes No';
}
.radioAlignerYes{
    grid-area: Yes;
}
.radioAlignerNo{
    grid-area: No;
}